import React, { useContext, useState } from "react";
import { UIContext } from "../../../providers/UIProvider";
import { Divider, Stack, Typography } from "@mui/material";
import { allChangeOrderOptions, GetSubmittedBasePlan } from "../../../apiCalls";
import { dispatchError } from "../../../common/fx";
import {
  ArrowCircleDown,
  ArrowCircleUp,
  ArrowRightAltOutlined,
} from "@mui/icons-material";
import { numberWithCommas } from "../../../utils/formatMoney";
import { gt, lt } from "lodash";
import useAccessControl from "../../../hooks/useAccessControl";

type Props = {
  newHomeInformation: any;
  loadingCalculation: any;
  setCurrentPrice: any;
  setLoadingCalculation: any;
  setPlanInfo: any;
  setPriceDifference: any;
  priceDifference: any;
  currentPrice: any;
  updatePlanMode: boolean;
};

const Step2 = ({
  newHomeInformation,
  loadingCalculation,
  setCurrentPrice,
  setPriceDifference,
  updatePlanMode,
  priceDifference,
  setLoadingCalculation,
  setPlanInfo,
  currentPrice,
}: Props) => {
  const checkNumber = (
    number: number,
    type: "positive" | "negative"
  ): boolean => {
    if (number === 0) return false; // handle zero case
    return type === "positive" ? gt(number, 0) : lt(number, 0);
  };
  const [state, dispatch]: any = useContext(UIContext);
  const AllChangeOrderOptionsAccess = useAccessControl("Job", "GetAllOptions");

  const calculatePriceDifference = (
    currentPrice: number,
    newPrice: number | string
  ): string => {
    const difference = Number(newPrice) - Number(currentPrice);

    setPriceDifference(difference.toString());
    return difference.toString();
  };

  React.useEffect(() => {
    console.log(newHomeInformation);
    calculatePriceDifference(
      currentPrice,
      newHomeInformation?.elevation?.salesPrice
    );

    return () => {
      setPriceDifference("");
    };
  }, [newHomeInformation, currentPrice]);

  React.useEffect(() => {
    const params = {
      jobId: state?.selectedJob?.jobId,
    };

    GetSubmittedBasePlan(
      params,
      (res: any) => {
        console.log(res);
        const planInfo = res.data;

        setCurrentPrice(planInfo.salesPrice);
        setPlanInfo(planInfo);

        setLoadingCalculation(false);
      },
      (err: any) => console.log(err)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllChangeOrderOptionsAccess]);

  return (
    <Stack gap={2} direction={"column"}>
      <Stack justifyContent={"space-between"} direction={"row"}>
        <div>
          <Typography fontWeight={"bold"}>Current Plan / Elevation</Typography>
          <Typography>
            {state.selectedJob.planNumber} /{" "}
            {state.selectedJob.planElevationNumber}
          </Typography>
        </div>

        <ArrowRightAltOutlined
          sx={{
            fontSize: "50px",
          }}
        />

        <div>
          <Typography fontWeight={"bold"}>New Plan / Elevation</Typography>
          <Typography textAlign={"end"}>
            {newHomeInformation?.plan.planNumber} /{" "}
            {newHomeInformation.elevation.planElevationNumber}
          </Typography>
        </div>
      </Stack>
      {!updatePlanMode && (
        <>
          <Divider orientation="horizontal" />

          <Stack justifyContent={"space-between"} direction={"row"}>
            <div>
              <Typography fontWeight={"bold"}>Current Price</Typography>
              {!loadingCalculation && (
                <Typography> ${numberWithCommas(currentPrice)}</Typography>
              )}
            </div>
            <div>
              <Typography fontWeight={"bold"}>Price Difference</Typography>

              <Stack
                gap={2}
                direction={"row"}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
              >
                {!loadingCalculation && (
                  <>
                    {Number(priceDifference) === 0 ? null : checkNumber(
                        Number(priceDifference),
                        "positive"
                      ) ? (
                      <ArrowCircleUp
                        sx={{ fontSize: "30px", color: "green" }}
                      />
                    ) : (
                      <ArrowCircleDown
                        sx={{ fontSize: "30px", color: "red" }}
                      />
                    )}
                    <Typography textAlign={"center"}>
                      ${numberWithCommas(priceDifference)}
                    </Typography>
                  </>
                )}
              </Stack>
            </div>
            <div>
              <Typography fontWeight={"bold"}>New Price</Typography>
              {!loadingCalculation && (
                <Typography textAlign={"end"}>
                  ${numberWithCommas(newHomeInformation?.elevation.salesPrice)}
                </Typography>
              )}
            </div>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Step2;
