import { useState, useEffect, useContext } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { Home } from "@mui/icons-material";

import {
  ChangeElevation,
  downloadChangeOrderPDF,
  getOptionsCatalog,
  getPlanElevations,
  getPlans,
  GetSubmittedBasePlan,
  UpdateElevation,
} from "../../apiCalls";
import { UIContext, UIState } from "../../providers/UIProvider";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import { dispatchError } from "../../common/fx";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import useAccessControl from "../../hooks/useAccessControl";
import { LoadingButton } from "@mui/lab";

export type homeInformation = {
  price: number;
  planElevationNumber: string;
  projectID: string;
  planElevationID: string;
  planNumber: string;
  planId: string;
  salesPriceControlId: string;
};

const PlanElevation = ({
  fetchIntention,
  fetchJobid,
  disabled,
  addressId,
  handleClose,
  title,
  updateElevationMode,
}: any) => {
  const [currentPrice, setCurrentPrice] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [priceDifference, setPriceDifference] = useState("");
  const [planInfo, setPlanInfo] = useState({ id: "" });
  const GetPlansAccess = useAccessControl("Plan", "GetPlans");

  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");
  const GetOptionsCatalogAccess = useAccessControl(
    "Option",
    "GetOptionsCatalog"
  );
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );
  const [loadingCalculation, setLoadingCalculation] = useState(true);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [specBuyer, setSpecBuyer] = useState<boolean>(false);

  const [newPlanInformation, setNewPlanInformation] = useState<homeInformation>(
    {
      price: 0,
      planElevationNumber: "",
      planNumber: "",
      projectID: "",
      planId: "",
      planElevationID: "",
      salesPriceControlId: "",
    }
  );

  const [currentStep, setCurrentStep] = useState<number>(1);

  useEffect(() => {
    GetPlanElevationsAccess &&
      getPlanElevations(
        {
          ProjectID: state.selectedJob.projectId,
          projectNumber: state.selectedJob.projectNumber,
          planId: state.selectedJob.planId,
          planNumber: state.selectedJob.planNumber,
        },
        (res: any) => {
          dispatch({
            type: "AvailableElevations",
            payload: res.data.sort(
              (
                a: {
                  planElevationNumber: string;
                },
                b: {
                  planElevationNumber: string;
                }
              ) => a.planElevationNumber.localeCompare(b.planElevationNumber)
            ),
          });
        },
        (err: any) =>
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, GetPlansAccess]);

  const toggleStep = () => {
    switch (currentStep) {
      case 1:
        return setCurrentStep(2);

      case 2:
        return updateElevationMode
          ? (setLoadingSubmit(true),
            UpdateElevation(
              {
                newPlanElevationId: newPlanInformation?.planElevationID,
                intentionId: addressId,
                jobId: state.selectedJob.jobId,
              },
              async (res: any) =>
                await fetchIntention((coUpdate: any) => {
                  dispatch({
                    type: "Snackbar",
                    payload: {
                      show: true,
                      message: `${state.selectedJob.address} elevation has been updated.`,
                      severity: "success",
                    },
                  });
                  setLoadingSubmit(false);
                  handleClose();
                  setCurrentStep(1);
                  setOpen(false);
                  fetchJobid();
                }),
              (err: any) => (
                dispatch(dispatchError(err.response.data)),
                setLoadingSubmit(false),
                setCurrentStep(1),
                setOpen(false)
              )
            ))
          : (setLoadingSubmit(true),
            ChangeElevation(
              {
                useSpecBuyer: specBuyer,
                planElevationOptionId:
                  planInfo?.id ?? "00000000-0000-0000-0000-000000000000",
                newPlanElevationSalesPriceControlId:
                  newPlanInformation?.salesPriceControlId.toString(),
                expectedPriceDifference: priceDifference.toString(),
              },
              async (res: any) =>
                await fetchIntention((coUpdate: any) => {
                  dispatch({
                    type: "Snackbar",
                    payload: {
                      show: true,
                      message: `${state.selectedJob.address} elevation has been updated. Remember to submit the change order.`,
                      severity: "success",
                    },
                  });
                  DownloadChangeOrderAccess &&
                    downloadChangeOrderPDF(
                      { changeOrderId: res.data },
                      async (res: {
                        fileData: "string";
                        contentType: "string";
                        blobName: "string";
                        documentName: "string";
                      }) => {
                        function base64ToBlob(
                          base64: string,
                          contentType: string = ""
                        ): Blob {
                          // Convert Base64 to a byte array
                          const byteCharacters = atob(base64);
                          const byteArrays = [];

                          for (
                            let offset = 0;
                            offset < byteCharacters.length;
                            offset += 512
                          ) {
                            const slice = byteCharacters.slice(
                              offset,
                              offset + 512
                            );
                            const byteNumbers = new Array(slice.length);

                            for (let i = 0; i < slice.length; i++) {
                              byteNumbers[i] = slice.charCodeAt(i);
                            }

                            const byteArray = new Uint8Array(byteNumbers);
                            byteArrays.push(byteArray);
                          }

                          // Create a blob from the byte array
                          return new Blob(byteArrays, { type: contentType });
                        }

                        function downloadPDF(
                          blobName: string,
                          contentType: string,
                          fileData: string
                        ) {
                          // Convert Base64 fileData to Blob
                          const blob = base64ToBlob(fileData, contentType);

                          // Create a Blob URL
                          const blobUrl = window.URL.createObjectURL(blob);

                          // Create a link element
                          const link = document.createElement("a");

                          // Set the download attribute with a filename
                          link.download = blobName;

                          // Set the href to the blob URL
                          link.href = blobUrl;

                          // Append the link to the document body
                          document.body.appendChild(link);

                          // Programmatically click the link to trigger the download
                          link.click();

                          // Clean-up: remove the link from the document
                          document.body.removeChild(link);
                        }

                        const latestCO =
                          coUpdate.data.intentions
                            .filter(
                              (order: { submittedAt: "string" }) =>
                                order.submittedAt !== null
                            )
                            .sort(
                              (
                                a: { submittedAt: "string" },
                                b: { submittedAt: "string" }
                              ) =>
                                moment(b.submittedAt).diff(
                                  moment(a.submittedAt)
                                )
                            )[0] || null;

                        downloadPDF(
                          res.documentName,
                          res.contentType,
                          res.fileData
                        );
                      },
                      (err: any) => (
                        dispatch(
                          dispatchError({
                            message: err.message,
                            statusText: err.response.statusText,
                            title: err.response.data.title,
                            status: err.response.status,
                            detail: err.response.data.detail,
                            data: err.response.data,
                          })
                        ),
                        setLoadingSubmit(false),
                        handleClose(),
                        setCurrentStep(1),
                        setOpen(false)
                      )
                    );
                  setLoadingSubmit(false);
                  setCurrentStep(1);
                  setOpen(false);
                  handleClose();
                }),
              (err: any) => dispatch(dispatchError(err.response.data))
            ));
    }
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        toolTipTitle={
          disabled
            ? "Must have a submitted CO with a base plan option to change elevation."
            : undefined
        }
        disabled={disabled}
        buttonVariant={"text"}
        buttonStyle={{
          color: "black",
        }}
        buttonText={title}
        icon={
          <Home
            sx={{
              marginRight: "5px",
            }}
          />
        }
        maxWidth="md"
        title={currentStep === 1 ? title : "Confirm Change"}
        content={
          <>
            <DialogContent>
              <Box p={2}>
                {currentStep === 1 && state.selectedJob.planNumber && (
                  <Step1
                    setSpecBuyer={setSpecBuyer}
                    updateElevationMode={updateElevationMode}
                    newHomeInformation={newPlanInformation}
                    setNewHomeInformation={setNewPlanInformation}
                  />
                )}
                {currentStep === 2 && (
                  <Step2
                    updateElevationMode={updateElevationMode}
                    priceDifference={priceDifference}
                    setPriceDifference={setPriceDifference}
                    setPlanInfo={setPlanInfo}
                    loadingCalculation={loadingCalculation}
                    setLoadingCalculation={setLoadingCalculation}
                    currentPrice={currentPrice}
                    setCurrentPrice={setCurrentPrice}
                    newHomeInformation={newPlanInformation}
                  />
                )}
                {console.log("newPlanInformation", newPlanInformation)}
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Box>
                {currentStep === 2 && (
                  <Button
                    onClick={() => {
                      setCurrentStep(1);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Back
                  </Button>
                )}
              </Box>
              <Stack direction="row" spacing={1}>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setCurrentStep(1);
                    handleClose();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={loadingSubmit}
                  disabled={
                    isEmpty(newPlanInformation.planElevationNumber) ||
                    state.selectedJob.planElevationNumber ===
                      newPlanInformation.planElevationNumber ||
                    loadingSubmit
                  }
                  sx={{
                    maxWidth: "10rem",
                  }}
                  onClick={(e) => toggleStep()}
                  variant="contained"
                  color="primary"
                >
                  {currentStep === 1
                    ? "Next"
                    : updateElevationMode
                    ? "Update"
                    : "Submit"}
                </LoadingButton>
              </Stack>
            </DialogActions>
          </>
        }
      />
    </>
  );
};

export default PlanElevation;
