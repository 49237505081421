import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useContext } from "react";
import {
  ElevationTypes,
  PlanTypes,
  UIContext,
  UIState,
} from "../../../providers/UIProvider";
import { isEmpty, isNil, isNull, uniqBy } from "lodash";
import { dispatchError } from "../../../common/fx";
import { getPlanElevations } from "../../../apiCalls";
import useAccessControl from "../../../hooks/useAccessControl";

const Step1 = ({
  setNewHomeInformation,
  newHomeInformation,
  setSpecBuyer,
  updatePlanMode,
}: any) => {
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");

  return (
    <Stack gap={2}>
      <Autocomplete
        defaultValue={""}
        fullWidth
        multiple={false}
        getOptionLabel={(option) => {
          console.log(option);
          return `${option.planNumber}${
            option.description ? ` - ${option.description}` : ""
          }`;
        }}
        onChange={(e, value) => {
          if (isNil(value)) {
            setNewHomeInformation({
              ...newHomeInformation,
              plan: {
                planId: "",
                planNumber: "",
                description: "",
              },
              elevation: {
                planElevationNumber: "",
              },
            });
          } else
            setNewHomeInformation({
              plan: {
                ...value,
              },
              elevation: {
                planElevationNumber: "",
              },
            });

          if (!isNull(value)) {
            if (value.projectID) {
              const { projectID, projectNumber, planId, planNumber } = value;
              GetPlanElevationsAccess &&
                getPlanElevations(
                  { projectID, projectNumber, planId, planNumber },
                  (res: any) => {
                    dispatch({
                      type: "AvailableElevations",
                      payload: res.data.sort(
                        (
                          a: {
                            planElevationNumber: string;
                          },
                          b: {
                            planElevationNumber: string;
                          }
                        ) =>
                          a.planElevationNumber.localeCompare(
                            b.planElevationNumber
                          )
                      ),
                    });
                  },
                  (err: any) =>
                    dispatch(
                      dispatchError({
                        message: err.message,
                        statusText: err.response.statusText,
                        title: err.response.data.title,
                        status: err.response.status,
                        detail: err.response.data.detail,
                        data: err.response.data,
                      })
                    )
                );
            }
          }
        }}
        onInputChange={(e, value, reason) => {
          if (!isNil(e)) {
            if (e.type !== "click") {
              dispatch({
                type: "CreateChangeOrderForm",
                payload: {
                  planOverride: value,
                },
              });
            }
          }
        }}
        options={uniqBy(state.availablePlans, (obj: PlanTypes) => {
          return obj.description;
        }).filter(
          (plan: PlanTypes) => plan.planNumber !== state.selectedJob.planNumber
        )}
        renderInput={(params: any) => (
          <TextField {...params} variant="outlined" label="Plan" fullWidth />
        )}
        size="medium"
        value={newHomeInformation.plan}
      />
      <Autocomplete
        disabled={isEmpty(newHomeInformation.plan.planId)}
        multiple={false}
        fullWidth
        size="medium"
        clearIcon={null}
        defaultValue={""}
        value={newHomeInformation.elevation || null}
        onChange={(e, value) => {
          console.log(value);
          if (isNil(value)) {
            setNewHomeInformation({
              ...newHomeInformation,
              elevation: {
                ...newHomeInformation.elevation,
                planElevationNumber: "",
              },
            });
          } else
            setNewHomeInformation({
              ...newHomeInformation,
              elevation: {
                ...value,
              },
            });
        }}
        options={state.availableElevations}
        getOptionLabel={(option) => `${option.planElevationNumber}`}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Elevation"
            fullWidth
          />
        )}
      />
      {!updatePlanMode && (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e, value) => {
                setSpecBuyer(value);
              }}
            />
          }
          label="Change buyer to Spec"
        />
      )}
    </Stack>
  );
};

export default Step1;
