import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for React Quill

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  TextField,
  Theme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Stack } from "@mui/system";
import { EditSystemMessage } from "../../apiCalls";
import { isEmpty } from "lodash";

type Props = {
  setRows?: any;
  rows?: any;
  fetchSystemMessages: any;
  open: boolean;
  setOpen: (value: boolean) => void;
  editObject?: any;
};

const EditNotificationModal = ({
  open,
  setOpen,
  setRows,
  rows,
  fetchSystemMessages,
  editObject,
}: Props) => {
  const [systemMessage, setSystemMessage] = useState<{
    type: "KnownIssue" | "NewFeature" | "SystemMaintenance" | "";
    title: string;
    message: string;
    expiresAt: any;
    id: string | null;
  }>({
    message: `${editObject.message}`,
    type: editObject.type,
    title: editObject.title,
    id: editObject.id,
    expiresAt: moment(editObject.expiredAt),
  });

  const handleConfirm = () => {
    EditSystemMessage(
      {
        id: systemMessage.id as string,
        type: systemMessage.type as
          | "KnownIssue"
          | "NewFeature"
          | "SystemMaintanance",
        title: systemMessage.title,
        message: systemMessage.message.replaceAll('"', "'"),
        expires: systemMessage.expiresAt,
      },
      (res: any) => (
        fetchSystemMessages(),
        setSystemMessage({
          type: "",
          title: "",
          message: "",
          expiresAt: moment(),
          id: null,
        })
      ),
      (err: any) => console.log(err)
    );
  };

  // Custom modules and toolbar configuration for React Quill
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }], // Dropdown with defaults from theme
      [{ align: [] }],
      ["link"],
      ["clean"], // Remove formatting button
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  useEffect(() => {
    editObject.id && setSystemMessage(editObject);
  }, [editObject]);

  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      data-testid="dialog-wrapper"
      onClose={setOpen}
      fullWidth
    >
      <>
        <DialogTitle
          data-testid={"dialog-title"}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          })}
        >
          Edit Notification
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack
              padding={(theme) => theme.spacing(2)}
              direction={"column"}
              height={"30rem"}
              spacing={2}
            >
              <Box>
                <Autocomplete
                  fullWidth
                  disableCloseOnSelect
                  size="small"
                  onChange={async (events, value) => {
                    setSystemMessage({
                      ...systemMessage,
                      type: value as
                        | "KnownIssue"
                        | "NewFeature"
                        | "SystemMaintenance",
                    });
                  }}
                  options={["KnownIssue", "NewFeature", "SystemMaintenance"]}
                  getOptionLabel={(option) => `${option}`}
                  value={systemMessage.type}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Notification Type"
                      fullWidth
                    />
                  )}
                  renderOption={(props, item) => (
                    <li {...props}>
                      <ListItemText>{`${item}`}</ListItemText>
                    </li>
                  )}
                />
              </Box>
              <Box>
                <DateTimePicker
                  sx={{
                    width: "100%",
                  }}
                  minDate={moment()}
                  label="Expiration Date"
                  value={systemMessage.expiresAt}
                  onChange={(newValue) =>
                    setSystemMessage({
                      ...systemMessage,
                      expiresAt: newValue,
                    })
                  }
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  label={"Title"}
                  value={systemMessage.title}
                  onChange={(e: any) =>
                    setSystemMessage({
                      ...systemMessage,
                      title: e.target.value,
                    })
                  }
                  variant="outlined"
                  fullWidth
                />
              </Box>
              <Box>
                <ReactQuill
                  theme="snow"
                  value={systemMessage.message}
                  onChange={(value: string) =>
                    setSystemMessage({ ...systemMessage, message: value })
                  }
                  modules={modules}
                  style={{ height: "200px" }}
                />
              </Box>
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async (e) => {
              await setOpen(false);
              setSystemMessage({
                type: "",
                title: "",
                message: "",
                expiresAt: moment(),
                id: null,
              });
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
              setOpen(false);
            }}
            disabled={
              isEmpty(systemMessage.type.trim()) ||
              isEmpty(systemMessage.message.trim()) ||
              isEmpty(systemMessage.title.trim()) ||
              isEmpty(systemMessage.expiresAt)
            }
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default EditNotificationModal;
