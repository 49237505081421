import { useState, useContext } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

import useAccessControl from "../../hooks/useAccessControl";
import { UIContext, UIState } from "../../providers/UIProvider";
import { BustaJob, downloadChangeOrderPDF } from "../../apiCalls";
import { dispatchError } from "../../common/fx";
import { LoadingButton } from "@mui/lab";

const ConfirmBust = ({
  address,
  disabled,
  handleClose,
  jobId,
  title,
  fetchIntention,
}: any) => {
  const GetOptionsCatalogAccess = useAccessControl(
    "Option",
    "GetOptionsCatalog"
  );

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [currentStep, setCurrentStep] = useState<number>(1);

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        toolTipTitle={
          disabled
            ? "Must have a submitted CO with a base plan option to change elevation."
            : undefined
        }
        disabled={disabled}
        buttonVariant={"text"}
        buttonStyle={{
          color: "black",
          paddingRight: "3rem",
        }}
        buttonText={title}
        icon={
          <AssignmentReturnIcon
            sx={{
              marginRight: "5px",
            }}
          />
        }
        maxWidth="sm"
        title={"Confirm Bust"}
        content={
          <>
            <DialogContent>
              <Box p={2}>
                <Typography variant="h6" color="text.primary">
                  Bust {address}?
                </Typography>
              </Box>
              <Box p={2}>
                <Typography variant="body2" color="text.primary">
                  This will create and submit a change order to delete all
                  previously submitted options across all change order(s) and
                  return the job price to $0.
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Stack
                sx={{ justifyContent: "space-between" }}
                direction="row"
                spacing={1}
              >
                <Button
                  onClick={() => {
                    setOpen(false);
                    setCurrentStep(1);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={loadingSubmit}
                  disabled={loadingSubmit}
                  onClick={(e) => (
                    setLoadingSubmit(true),
                    BustaJob(
                      {
                        jobId: jobId,
                      },
                      async (res: any) => (
                        await downloadChangeOrderPDF(
                          { changeOrderId: res.data },
                          async (res: {
                            fileData: "string";
                            contentType: "string";
                            blobName: "string";
                            documentName: "string";
                          }) => {
                            function base64ToBlob(
                              base64: string,
                              contentType: string = ""
                            ): Blob {
                              // Convert Base64 to a byte array
                              const byteCharacters = atob(base64);
                              const byteArrays = [];

                              for (
                                let offset = 0;
                                offset < byteCharacters.length;
                                offset += 512
                              ) {
                                const slice = byteCharacters.slice(
                                  offset,
                                  offset + 512
                                );
                                const byteNumbers = new Array(slice.length);

                                for (let i = 0; i < slice.length; i++) {
                                  byteNumbers[i] = slice.charCodeAt(i);
                                }

                                const byteArray = new Uint8Array(byteNumbers);
                                byteArrays.push(byteArray);
                              }

                              // Create a blob from the byte array
                              return new Blob(byteArrays, {
                                type: contentType,
                              });
                            }

                            function downloadPDF(
                              blobName: string,
                              contentType: string,
                              fileData: string
                            ) {
                              // Convert Base64 fileData to Blob
                              const blob = base64ToBlob(fileData, contentType);

                              // Create a Blob URL
                              const blobUrl = window.URL.createObjectURL(blob);

                              // Create a link element
                              const link = document.createElement("a");

                              // Set the download attribute with a filename
                              link.download = blobName;

                              // Set the href to the blob URL
                              link.href = blobUrl;

                              // Append the link to the document body
                              document.body.appendChild(link);

                              // Programmatically click the link to trigger the download
                              link.click();

                              // Clean-up: remove the link from the document
                              document.body.removeChild(link);
                            }

                            downloadPDF(
                              res.documentName,
                              res.contentType,
                              res.fileData
                            );
                          },
                          (err: any) =>
                            dispatch(
                              dispatchError({
                                message: err.message,
                                statusText: err.response.statusText,
                                title: err.response.data.title,
                                status: err.response.status,
                                detail: err.response.data.detail,
                                data: err.response.data,
                              })
                            )
                        ),
                        await fetchIntention((coUpdate: any) => {
                          dispatch({
                            type: "Snackbar",
                            payload: {
                              show: true,
                              message: `${state.selectedJob.address} has been busted.`,
                              severity: "success",
                            },
                          });
                          setOpen(false);
                          setCurrentStep(1);
                          setLoadingSubmit(false);
                          handleClose();
                        }, res.data)
                      ),
                      (err: any) => dispatch(dispatchError(err.response.data))
                    )
                  )}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>
              </Stack>
            </DialogActions>
          </>
        }
      />
    </>
  );
};

export default ConfirmBust;
